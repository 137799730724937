/**@jsx jsx */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Text, Image, Button, jsx } from 'theme-ui'
import Tabs from '@components/Tabs'
import { Panel } from '@bumaga/tabs'
import { ContentfulEvent } from '../../graphqlTypes'

const EventTabHeaders = [
  'Virtual events',
  'Side events',
  'Annual meetings',
  'Past events',
]

export const EventCard = ({ event }: { event: ContentfulEvent }) => {
  return (
    <Flex
      sx={{
        flexDirection: ['column'],
      }}
    >
      <EventCoverImage event={event} />
      <a href={event?.rsvpLink}>
        <Button
          sx={{
            my: [2],
            width: '100%',
            textDecoration: 'none',
            color: event?.rsvpLink ? 'white' : 'lightgrey',
          }}
        >
          RSVP
        </Button>
      </a>
      <Text
        sx={{
          my: [1],
          maxWidth: '300px',
          fontSize: [null, null, null, 3, 3],
          fontFamily: 'Futura Medium',
          color: 'primary.one',
        }}
      >
        {event.description}
      </Text>
      <Text
        sx={{
          maxWidth: '300px',
          my: [null, null, null, 1, null],
          fontSize: [null, null, null, 3, 4],
          fontFamily: 'Futura Bold',
          color: 'black',
        }}
      >
        {event.title}
      </Text>
      <Text
        sx={{
          maxWidth: '300px',
          mt: [0],
          fontSize: [null, null, null, 3, 3],
          fontFamily: 'Futura Book Regular',
          color: 'grey',
        }}
      >
        {event.eventType === 'Virtual' ? 'Virtual' : event?.locationText}{' '}
        {getEventDate(event?.date)}
      </Text>
    </Flex>
  )
}

const getEventDate = (eventDate: string) => {
  if (!eventDate) {
    return
  }

  const date = new Date(eventDate)
  const dateText = date.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  return `- ${dateText}`
}

export const EventTabs = () => {
  // contentful fragments
  // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/src/fragments.js
  const data = useStaticQuery(
    graphql`
      query contentfulEvents {
        events: allContentfulEvent {
          nodes {
            date
            eventType
            title
            rsvpLink
            locationText
            description
            eventImage {
              fixed(width: 500, height: 300) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    `
  )

  const panels = EventTabHeaders.map(tab => {
    let events

    if (tab !== 'Past events') {
      events = data.events.nodes.filter((node: ContentfulEvent) => {
        // if event type is null we can check against a non-event type
        return tab.includes(node.eventType || 'null')
      })
    } else {
      events = data.events.nodes.filter((node: ContentfulEvent) => {
        if (!node.date) return false

        const today = new Date()
        const eventDate = new Date(node?.date)
        return eventDate <= today
      })
    }

    // sort our events in order of latest
    events.sort((a, b) => {
      const dateA = new Date(a.date)
      const dateB = new Date(b.date)
      return dateB - dateA
    })

    return (
      <Panel key={tab}>
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {events.map((event: ContentfulEvent, index: number) => (
            <Flex
              key={event.title || index}
              sx={{
                mb: [4],
                mr: [4],
              }}
            >
              <EventCard event={event} />
            </Flex>
          ))}
        </Flex>
      </Panel>
    )
  })

  return <Tabs tabHeaderConfig={EventTabHeaders} panels={panels} />
}

const EventCoverImage = ({ event }: { event: ContentfulEvent }) => {
  const CoverImage = () =>
    event?.eventImage?.fixed ? (
      <Img
        fixed={event.eventImage.fixed}
        key={event.eventImage.fixed.src}
        alt={`Image for ${event.title}`}
        style={{ width: '100%' }}
      />
    ) : (
      <Image
        sx={{
          width: '300px',
          height: '300px',
          mb: [2],
        }}
        src={`placeholder.png`}
        alt="event placeholder image"
      />
    )

  return event?.rsvpLink ? (
    <a href={event?.rsvpLink}>
      <CoverImage />
    </a>
  ) : (
    <CoverImage />
  )
}

export default EventTabs
